import { useEffect, useState } from 'react'

function useMobileQuery() : { matches: boolean } {
  const [mobileQuery, setMobileQuery] = useState<any>({
    matches: window.innerWidth <= 768 ? true : false,
  })

  useEffect(() => {
    let mediaQuery = window.matchMedia("(max-width: 768px)")
    mediaQuery.addEventListener("change", setMobileQuery)
    return () => mediaQuery.removeEventListener("change", setMobileQuery)
  }, [])
  
  return mobileQuery
}

export default useMobileQuery