import SpanBlock from "./SpanBlock";

/**
 * テキストブロック
 */
 export default class TextPosition {

  y: number[] = [];
  x: number[] = [];
  rotate: number[] = [];
  text: string = "";
  spanBlocks: SpanBlock[] = [];

  constructor() {}

  

}