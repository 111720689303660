/**
 * テキストブロック
 */
export default class SpanBlock {

  fontWeight: string = "normal";
  fontStyle: string = "normal";
  fill: string = "#000";
  text: string = "";
  
  constructor() {}

  

}
