import { EditorState, Modifier } from "draft-js";

export default class EditorUtil {
  constructor() {}

  // カスタムスタイルをクリアする
  static removeInlineStyles(editorState: EditorState, customStyleMap: {}) {
    const contentState = editorState.getCurrentContent();
    const contentWithoutStyles = Object.keys(customStyleMap).reduce(
      (newContentState: any, key: string) => 
        Modifier.removeInlineStyle(
          newContentState,
          editorState.getSelection(),
          key
        ),
      contentState
    );
    return EditorState.push(
      editorState,
      contentWithoutStyles,
      'change-inline-style'
    );
  }

  // 指定した文字色のカスタムスタイルと適用する
  static appyInlineStyle(editorState: EditorState, style: string) {
    const neeContentState = Modifier.applyInlineStyle(
      editorState.getCurrentContent(), 
      editorState.getSelection(), 
      style);
    return EditorState.push(
      editorState,
      neeContentState,
      'change-inline-style'
    );
  };
}