import React, { createContext, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import EditorContext from './Components/EditorContext';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { } from 'react-router-dom';
import Edit from './Pages/Edit';
import Preview from './Pages/Preview';
import TestComponet from './Components/TestComponet';
import Top from './Components/Top';

function App() {
  return (
    <div className="App">
      {/* <header>WEB原稿用紙エディタ</header> */}
      <EditorContext>
        <BrowserRouter>
          <Routes>
            <Route path={`${process.env.PUBLIC_URL}/`} element={<Top />}/>
            <Route path={`${process.env.PUBLIC_URL}/edit`} element={<Edit />}/>
            <Route path={`${process.env.PUBLIC_URL}/preview/:id`}  element={<Preview />} />
            <Route path="%PUBLIC_URL%/test" element={<TestComponet />} />
          </Routes>
        </BrowserRouter>
      </EditorContext>
    </div>
  );
}

export default App;
