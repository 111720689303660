import SpanBlock from "./SpanBlock";

/**
 * ページ情報を格納するクラス
 */
export default class Page {

  //ページ番号
  pageNo: number;

  //行のリスト
  lines: string[];

  //行別、書式別のブロックリスト
  linesSpanBlocks: Array<SpanBlock[]>;

  /**
   * コンストラクタ
   * @param lines 行のリスト
   * @param pageNo ページ番号
   */
  constructor(lines: string[], linesSpanBlocks: Array<SpanBlock[]>, pageNo: number) {
    this.lines = lines;
    this.linesSpanBlocks = linesSpanBlocks;
    this.pageNo = pageNo;
  }
} 