import React, { createContext, useEffect, useRef } from 'react';
import { useState } from 'react';
import { convertFromRaw, convertToRaw, Editor, EditorState, Modifier, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';

/**
 * コンテキストの型
 */
type ContextType = {
  cnt: number,
  setCnt: (c: number) => void,
  editorState: EditorState,
  setEditorState: (state: EditorState) => void,
  setTextChangeHandler: (handler: (state: EditorState) => void) => void,
  previewMode: boolean,
  setPreviewMode: (value: boolean) => void,
};

/**
 * コンテキストの作成
 */
export const TextStateContext = createContext<ContextType>({} as ContextType);

/**
 * 全体の状態を管理するコンテキストクラス
 * @param prop プロパティ
 * @returns コンポーネント
 */
function EditorContext(prop: any) {
  //テキストエディタのステータス
  const [editorState, changeEditorState] = useState(
    () => {
      const json = localStorage.getItem("savedContent")
      if (json) {
        const contentState = convertFromRaw(JSON.parse(json))
        const newEditorState = EditorState.createWithContent(contentState)
        //console.log(json)
        return newEditorState
      } else {
        return EditorState.createEmpty()
      }
    },
  );
  const [cnt, changeCnt] = useState(0);
  const [previewMode, setPreviewMode] = useState(false);

  //ハンドラーの配列
  const [list, setList] = useState(new Array<Function>());

  //ハンドラーの追加
  const setTextChangeHandler = (handler: (state: EditorState) => void) => {
    setList([handler]);
  };

  const setCnt = (c: number) => {
    changeCnt(c);
  };

  /**
   * エディタの内容をローカルストレージに保存する関数
   */
  const saveContent = () => {
    const contentState = editorState.getCurrentContent();
    const raw = convertToRaw(contentState);
    const json = JSON.stringify(raw, null, 2);
    localStorage.setItem("savedContent", json);
  };

  /**
   * EditorStateの変更
   * @param state 
   */
  const setEditorState = (state: EditorState) => {
    changeEditorState(state)
    saveContent()
    list.forEach(n => n(state))
  };

  return (
    <TextStateContext.Provider value={{ 
      cnt,
      setCnt,
      editorState, 
      setEditorState,
      setTextChangeHandler,
      previewMode,
      setPreviewMode }}>
      {prop.children}
    </TextStateContext.Provider>
  );
}

export default EditorContext;
