import React, { useRef } from 'react';
import { useState } from 'react';
import { Editor, EditorState, Modifier, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { CirclePicker, GithubPicker, SketchPicker } from 'react-color';
import { FaTiktok, FaTwitter, FaYoutube } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';


function TestComponet() {

  // const [displayColorPicker, setDisplayColorPicker] = useState(false);

  // const handleClick = () => {
  //   setDisplayColorPicker(!displayColorPicker);
  // };

  // // //const c = ['#000000', '#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#5300EB', '#EB9694', '#FAD0C3', '#FEF3BD', '#C1E1C5', '#BEDADC', '#C4DEF6', '#BED3F3']

  // // const colors = ['#000000', '#FFCCCC', '#FFAAAA', '#FF8888', '#FF5555', '#FF3333', '#FF1111']

  // // const handleClose = () => {
  // //   setDisplayColorPicker(false);
  // // };

  // // const handleChange = (color: any) => {
  // //   //this.setState({ color: color.rgb })
  // //   console.log(color.hex);
  // // };

  // // //ピッカーをポップアップ
  // // const popover: {} = {
  // //   position: 'absolute',
  // //   zIndex: '2',
  // // }

  // // //ピッカー以外の領域を所をクリックした時に閉じるためのカバー
  // // const cover: {} = {
  // //   position: 'fixed',
  // //   top: '0px',
  // //   right: '0px',
  // //   bottom: '0px',
  // //   left: '0px',
  // // }

  return (
    <div style={{ margin: "200px"}}>
      <IconContext.Provider value={{ color: "blue", size: "10rem" }}>
        <FaYoutube/>
        <FaTwitter/>
        <FaTiktok/>
        <FaYoutube color="red"/>
      </IconContext.Provider>
    </div>
  );
}

export default TestComponet;
