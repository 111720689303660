import { ContentBlock, EditorState } from "draft-js";
import { stringify } from "querystring";
import { start } from "repl";
import { text } from "stream/consumers";
import Page from "../Domains/Page";
import SpanBlock from "../Domains/SpanBlock";
import TextPosition from "../Domains/TextPosition";

export default class ViewerUtil {

  static readonly COL_COUNT = 21;
  static readonly DATA_COL_COUNT = 20;
  static readonly ROW_COUNT = 20;
  static readonly END_KINSOKU = "」』）｝】＞≫、。,."; //行頭禁則文字（閉じ括弧、句読点）
  static readonly ROTATE_CHAR = "ー-「」【】『』（）()［］｛｝{}＜＞<>《》";
  static readonly RIGHT_CHAR = "、。";

  static readonly COLOR_REGEX = /C([A-F0-9]{6})/;

  static readonly MODE_OKURIDASHI = "MODE_OKURIDASHI";

  /**
   * テキスト描画用の座標データ作成
   * @param page 
   */
  static createTextData(viewBoxSize: {width:number, height:number}, 
    page: Page, 
    lineIndex: number): TextPosition {
      const w = viewBoxSize.width / ViewerUtil.COL_COUNT;   //１マスの幅
      const h = viewBoxSize.height / 20;                    //１マスの高さ
      const dispLineIndex = ViewerUtil.DATA_COL_COUNT - lineIndex;  //左側から数えた列インデックス
      const yomiW = Math.ceil(w / 5);   //読みの幅

      const ret = new TextPosition();

      //X座標の設定
      let baseX = (ViewerUtil.DATA_COL_COUNT / 2) < dispLineIndex ? w * (dispLineIndex + 1) : w * dispLineIndex;
      baseX = baseX - (yomiW + (w - yomiW) / 2);  //マスの中心座標設定

      //１列分の文字ごとの座標情報設定
      for (let i = 0; i < page.lines[lineIndex].length; i++) {
        const c = page.lines[lineIndex][i]; 
        //括弧等の文字の９０度回転判定
        const r = ViewerUtil.ROTATE_CHAR.indexOf(c) >= 0 ? 90 : 0;

        //XY座標
        let x = baseX;
        let y = h * i + (h / 2) - (r == 90 ? h / 2 : 0);

        if (ViewerUtil.RIGHT_CHAR.indexOf(c) >= 0) {
          //句読点を上寄寄せにする処理
          y -= (h / 2 - 3);
          x += (w - yomiW) / 2;
        }
        
        //１文字分の座標・回転情報を設定
        ret.x.push(x);
        ret.y.push(y);
        ret.rotate.push(r);
      }
      
      ret.text = page.lines[lineIndex];
      ret.spanBlocks = page.linesSpanBlocks[lineIndex];
      return ret;
  }


  /**
   * 線のPath作成
   * @param viewBoxSize 
   */
  static createLinePath(viewBoxSize: {width:number, height:number}) {
    const w = viewBoxSize.width / ViewerUtil.COL_COUNT;
    const h = viewBoxSize.height / 20;
    const yomiW = Math.ceil(w / 5);
    let paths = [];

    let x = 0;
    for (let i = 0; i < ViewerUtil.COL_COUNT; i++) {
      x = (i + 1) * w;
      if (i != 10) {
        paths.push(`M ${x-yomiW},0`);
        paths.push(`L ${x-yomiW},${viewBoxSize.height} Z`);
      }
      paths.push(`M ${x},0`);
      paths.push(`L ${x},${viewBoxSize.height} Z`);
    }

    let y = 0;
    for (let i = 0; i < ViewerUtil.ROW_COUNT; i++) {
      y = (i + 1) * h;
      for (let j = 0; j < ViewerUtil.COL_COUNT; j++) {
        x = j * w;
        if (j != 10) {
          paths.push(`M ${x},${y}`);
          paths.push(`L ${x + w - yomiW},${y} Z`);
        }
      }
    }
  
    return paths.join(" ");
  }


  /**
   * エディタに入力された文字列を、原稿用紙ビューワー用にページ分割した文字列にする
   * @param editorState 
   * @returns 
   */
  static calc(editorState: EditorState, mode: string): Page[] {
    const LINE_CHAR_SIZE = 20;    //１行あたりの文字数
    const PAGE_LINE_SIZE = 20;    //１ページあたりの行数
    const lines: string[] = [];
    const spanBlocks: Array<SpanBlock>[] = [];

    // ブロックの数だけ繰り返し
    var blocks = editorState.getCurrentContent().getBlocksAsArray();
    blocks.forEach((block, ind) => {
      //console.log(ind + ":" + block.getText());
      const blockText = block.getText();

      if (!blockText || blockText.length == 0) {
        //改行だけの行は行数だけカウント
        lines.push("");
        spanBlocks.push([new SpanBlock()]);
        return;
      }

      let currentIndex = 0;
      while (currentIndex < blockText.length) {
        const startIndex = currentIndex;

        //１行分（２０文字）取得
        let t = blockText.substr(currentIndex, LINE_CHAR_SIZE);
        currentIndex += t.length;

        //「送り出し」処理時に、次の行の先頭文字が行頭禁止文字の場合
        const n = blockText.substr(currentIndex, 1);
        if (mode == ViewerUtil.MODE_OKURIDASHI && n && ViewerUtil.END_KINSOKU.indexOf(n) >= 0) {

          //末尾から禁則文字以外の文字を探す
          let lastNomarlIndex = 0;
          for(lastNomarlIndex = t.length - 1; lastNomarlIndex >= 1; lastNomarlIndex--) {
            if (ViewerUtil.END_KINSOKU.indexOf(t.substr(lastNomarlIndex, 1)) < 0) break;
          }

          //「追い出し」処理時に、次の行に送る最終文字インデックスを設定
          const nextSendCnt = t.length - lastNomarlIndex;
          currentIndex -= nextSendCnt;
        }

        //「ぶら下がり」「追い込み」処理時に、行頭禁則文字を行末尾にくっつける
        let endChar = "";
        if (mode != ViewerUtil.MODE_OKURIDASHI) {
          while (true) {
            const c = blockText.substr(currentIndex, 1);
            if (c && ViewerUtil.END_KINSOKU.indexOf(c) >= 0) {
              currentIndex++;
              continue;
            }
            break;
          }
        }

        const lineText = blockText.substring(startIndex, currentIndex);
        lines.push(lineText);
        spanBlocks.push(ViewerUtil._createSpanBlocks(block, startIndex, currentIndex));
      }
    });

    //ページの配列を作成
    const pages = new Array<Page>();
    while (lines.length > 0) {
      const page = new Page(
          lines.splice(0, PAGE_LINE_SIZE), 
          spanBlocks.splice(0, PAGE_LINE_SIZE), 
          pages.length + 1);
      pages.push(page);
    }

    if (pages.length == 0) pages.push(new Page([], [], 1));
    return pages;
  }

  /**
   * １行分のテキストをインライン・スタイル単位に分割
   * @param block ブロック
   * @param startIndex ブロック内の開始インデックス
   * @param endIndex ブロック内の終了インデックス
   */
  static _createSpanBlocks(block: ContentBlock, startIndex: number, endIndex: number) {
    let prevKey = "";
    const spanBlocks: SpanBlock[] = [];
    const blockText = block.getText();

    for(let i = startIndex; i < endIndex; i++) {
      const metadate =　block.getCharacterList().get(i);
      const inlineStyles = metadate?.getStyle().sort().join(",") ?? "";
      let span: SpanBlock;

      if (prevKey != inlineStyles || spanBlocks.length == 0) {
        span = new SpanBlock();
        //太字・斜体の反映
        if (inlineStyles.indexOf("BOLD") >= 0) span.fontWeight = "bold";
        if (inlineStyles.indexOf("ITALIC") >= 0) span.fontStyle = "italic";
        //文字色の反映
        let match = ViewerUtil.COLOR_REGEX.exec(inlineStyles);
        if (match) {
          span.fill = "#" + match[1];
        }
        spanBlocks.push(span);
      } else {
        span = spanBlocks[spanBlocks.length - 1];
      }

      //console.log(`${blockText.substr(i, 1)}  ${inlineStyles}`);
      span.text += blockText.substr(i, 1);
      prevKey = inlineStyles;
    }

    //console.log(spanBlocks)
    return spanBlocks;
  }
}



// const getText = () => {
//   var blocks = editorState.getCurrentContent().getBlocksAsArray();
//   blocks.map(block => {
//     const text = block.getText();
//     //console.log(text);
//     block.getCharacterList().map((metadate, index) => {
//       const char = text.substr(Number(index), 1);
//       //対象の１文字に適用されているインラインスタイルを取得（カンマ区切り）
//       const inlineStyles = metadate?.getStyle().join(",");
//       //対象の１文字とインラインスタイルを出力
//       console.log(char + "（" + inlineStyles + "）");
//     })
//   });
// };