import React, { useContext, useRef } from 'react';
import { useState } from 'react';
import { Editor, EditorState, Modifier, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { TextStateContext } from './EditorContext';
import style from './PageView.module.css';
import Page from '../Domains/Page';
import ViewerUtil from '../Utils/ViewerUtil';

type Props = {
  page: Page,
  width?: any 
}

function PageView(props: Props) {
  const { editorState } = useContext(TextStateContext);
  const viewBoxSize = { width: 294 * 2, height: 210 * 2};
  const linepath = ViewerUtil.createLinePath(viewBoxSize);

  const textPosDatas = 
    props.page.lines.map((l, index) => ViewerUtil.createTextData(viewBoxSize, props.page, index));
  //const textPosData = ViewerUtil.createTextData(viewBoxSize, prop.page, )
  
  return (
    <div className={style.wrapper}>
      <svg viewBox={`0 0 ${viewBoxSize.width} ${viewBoxSize.height}`} 
        width={props.width ?? "100%"}  
        xmlns="http://www.w3.org/2000/svg">
        <rect className={style.pageRect} x="0" y="0" width="100%" height="100%" />
        <path 
          d={linepath}
          stroke="rgb(255, 198, 145)" strokeWidth="1" fill="none"/>
          {textPosDatas.map((t, i) => 
            <text key={i} x={t.x.join(",")} 
              y={t.y.join(",")} 
              rotate={t.rotate.join(",")}
              fontSize="14"
              textAnchor="middle"
              dominantBaseline="middle">
                {t.spanBlocks.map((span, ii) => 
                  <tspan key={ii} fill={span.fill} fontWeight={span.fontWeight} fontStyle={span.fontStyle}>{span.text}</tspan>
                )}
            </text>
          )}
          {/* <text x="0,30,60,90,120,150,180" y="110,150,180,116,118,120,122" rotate="0,0,0,90,90,0,0">本日は「<tspan font-weight="bolder">天</tspan>なり</text> */}

          <rect x={viewBoxSize.width / 2 - 11} y={(viewBoxSize.height / 20) * 15.5 - 2} 
            width="22" height="4" 
            strokeWidth="0" 
            fill="rgb(255, 198, 145)" />

          {/* 魚尾 */}
          <path d={`M 283 95 L 305 95 L 305 101 C 305 101 294 95 283 101 L 283 95`} 
            strokeWidth="0" 
            fill="rgb(255, 198, 145)"/>
      </svg>
      <div className={style.pageNo}>
        - {props.page.pageNo} -
      </div>
    </div>
  );
}

export default PageView;
