import React, { createContext, useEffect, useState } from 'react';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import PageView from '../Components/PageView';
import Page from '../Domains/Page';
import style from './Preview.module.css';

/**
 * プレビュー画面
 * @returns 
 */
function Preview(): JSX.Element {
  const [pages, setPages] = useState<Page[]>([])
  const [playAnimation, setPlayAnimation] = useState(false);
  const params = useParams();
  const el = useRef(null);

  useEffect(() => {

    const str = window.localStorage.getItem(`pages${params.id}`)
    if (str) {
      setPages(JSON.parse(str) as Page[]);
    }

    const onPageLoad = () => {
      if (!playAnimation) {
        setTimeout(() => {
          window.print()
        }, 2000);
      }
      setPlayAnimation(true);
    };

    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);



  return (
    <>
      <header>WEB原稿用紙エディタ</header>
      <div ref={el} className={style.container}>
        {pages.map((data, index) =>
          <div data-page className={style.inner} key={index}>
            {index > 0 && <div className={style.newpage} />}
            <PageView page={data} width="26cm" />
          </div>
        )}
      </div>
    </>
  );
}

export default Preview;
