import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useState } from 'react';
import { Editor, EditorState, Modifier, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { TextStateContext } from './EditorContext';
import ViewerUtil from '../Utils/ViewerUtil';
import { FaEdit, FaPrint } from 'react-icons/fa';
import Page from '../Domains/Page';
import PageView from './PageView';
import style from './Viewer.module.css';
import useMobileQuery from '../Hooks/useMobileQuery';
import Adsense from './Adsense';

/**
 * ビューワー コンポーネント
 * @param props 
 * @returns 
 */
function Viewer(props: any) {
  const { editorState, previewMode, setPreviewMode } = useContext(TextStateContext)
  const [lineCnt, setLineCnt] = useState(0)
  const [pages, setPages] = useState(new Array<Page>())
  const mobileQuery = useMobileQuery()

  useEffect(() => {
    console.log("calc")
    const tmp = ViewerUtil.calc(editorState, ViewerUtil.MODE_OKURIDASHI)
    setPages(tmp)
    setLineCnt(tmp.map(n => n.lines.length).reduce((p, c) => p + c, 0))
  }, [editorState])

  const test = () => {
    //ViewerUtil.calc(editorState);

  }

  // printボタン押下
  const onClickPrint = () => {
    const id = Math.random().toString(32).substring(2)
    window.localStorage.setItem(`pages${id}`, JSON.stringify(pages))
    window.open(`${process.env.PUBLIC_URL}/preview/${id}`)
  }

  /**
   * 編集に戻る
   */
  const onClickEdit = useCallback(
    () => {
      setPreviewMode(false)
    },
    [],
  )


  return (
    <div className={style.container}>
      <div className={style.toolbar}>
        {previewMode && (
          <button onClick={onClickEdit}><FaEdit /> 編集に戻る</button>
        )}
        <button onClick={onClickPrint}><FaPrint /> プリント</button>
        {/* <button >テキスト取得</button> */}
        {/* <button onClick={test}>TEST{cnt}</button> */}

        <div className={style.cntContainer}>
          行数:{lineCnt}　枚数:{pages.length}
        </div>
      </div>
      <div className={style.scrollContainer}>
        {pages.map((data, index) => (
          <div key={index}>
            <PageView page={data} />
            {index == 0 && (
              <Adsense/>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Viewer;
