import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import logo from './logo.svg';
import TextEditor from '../Components/TextEditor';
import Viewer from '../Components/Viewer';
import useMobileQuery from '../Hooks/useMobileQuery';
import { TextStateContext } from '../Components/EditorContext';

function Edit() {
  const mobileQuery = useMobileQuery()
  const { previewMode, setPreviewMode } = useContext(TextStateContext);

  /**
   * プレビューモードの表示
   */
  const handleShowPreview =useCallback(
    () => {
      setPreviewMode(true)
    }, [],
  )
  
  return (
    <div>
      <header>WEB原稿用紙エディタ</header>
      {!mobileQuery.matches ?
        (
          <div className="flex-container">
            <div>
              <TextEditor />
            </div>
            <div>
              <Viewer />
            </div>
          </div>
        ) :
        (
          <>
            {!previewMode && (
              <div>
                <TextEditor handleShowPreview={handleShowPreview} />
              </div>
            )}
            {previewMode && (
              <div>
                <Viewer />
              </div>
            )}
          </>
        )}
    </div>
  );
}

export default Edit;
