import React, { useContext, useEffect, useRef } from 'react';
import { useState } from 'react';
import { Editor, EditorState, RichUtils, SelectionState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { FaBeer, FaBold, FaYoutube } from 'react-icons/fa';
import { BiBold, BiFontColor, BiItalic } from 'react-icons/bi';
import { VscOpenPreview } from "react-icons/vsc";
import style from './TextEditor.module.css';
import { TextStateContext } from './EditorContext';
import TestComponet from './TestComponet';
import EditorUtil from '../Utils/EditorUtil';
import { GithubPicker } from 'react-color';
import useMobileQuery from '../Hooks/useMobileQuery';


//カラーピッカーで選択可能な色s
const colors = ['#000000', '#888888', '#FF1111', '#11FF11', 
                '#1111FF', '#FF3333', '#00CCCC', '#fff352']
let dictionary = Object.assign({}, ...colors.map((x) => ({[`C${x.substr(1)}`]: { color: x }})));

//エディタのカスタムスタイルマップ
const colorStyleMap = {
  ...dictionary
};

interface TextEditorProp {
  handleShowPreview?: (value: boolean) => void
}

/**
 * テキストエディタ
 * @returns 
 */
function TextEditor({
  handleShowPreview
} : TextEditorProp) {

  const editorRef = useRef<Editor>(null);
  const { editorState, setEditorState } = useContext(TextStateContext);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const mobileQuery = useMobileQuery()

  useEffect(() => {
    
  }, [])

  /**
   * 太字・斜体のインラインスタイルを切り替える
   */
  const toggleInlineStyle = (e: any, inlineStyle: string) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  /**
   * カラーピッカーの表示
   */
  const showColorPicker = (e: any) => {
    setDisplayColorPicker(true);
  };

  /**
   * カラーピッカーでの色選択
   * @param color 
   */
  const handleChange = (color: any) => {
    //選択された色のスタイルを選択範囲のテキストに反映する
    let newState = EditorUtil.removeInlineStyles(editorState, colorStyleMap);
    const s = `C${color.hex.substr(1).toUpperCase()}`;
    newState = EditorUtil.appyInlineStyle(newState, s);
    setEditorState(newState);

    const ss = newState.getSelection()
    const c = newState.getCurrentContent()
    const focusOffset = ss.getFocusOffset()
    const anchorKey = ss.getAnchorKey()
    let selectionState = SelectionState.createEmpty(ss.getAnchorKey())

    // console.log anchorKey, focusOffset
    selectionState = selectionState.merge({
      anchorOffset: ss.getAnchorOffset(),
      focusKey: anchorKey,
      focusOffset: focusOffset
    })

    //editorRef.current?.focus();
    let newStatew = EditorState.forceSelection(newState, selectionState)
    setEditorState(newStatew);

    //ピッカーを閉じる
    setDisplayColorPicker(false);
  };

  /**
   * ツールバーのボタンを作る共通関数
   * @param onClick イベントハンドラ
   * @param icon アイコン
   * @returns ボタン
   */
  const toolButton = (onClick: (e: any) => void, icon: JSX.Element) : JSX.Element => {
    return (
      <div className={style.iconButton}
        onClick={onClick}
        onMouseDown={(e) => e.preventDefault()}>
        {icon}
      </div>
    )
  };

  const iconStyle = { size: "1.5rem", color: "#555" };

  return (
    <div className={style.container}>
      <div>
        <div className={style.toolbar}>
          {toolButton((e) => toggleInlineStyle(e, "BOLD"), <BiBold {...iconStyle} />)}
          {toolButton((e) => toggleInlineStyle(e, "ITALIC"), <BiItalic {...iconStyle}/>)}
          <div>
            {toolButton(showColorPicker, <BiFontColor {...iconStyle}/>)}
            {displayColorPicker &&
              <div className={style.popover}>
                <div className={style.cover} onClick={() => setDisplayColorPicker(false)} />
                <GithubPicker colors={colors} onChange={handleChange} />
              </div>
            }
          </div>
          {mobileQuery.matches && handleShowPreview && (
            <div style={{ marginLeft: "auto"}}>
              {toolButton(() => handleShowPreview(true), <VscOpenPreview {...iconStyle}/>)}
            </div>
          )}
        </div>

        <div className={style.editorContainer}>
          <Editor
            ref={editorRef}
            editorState={editorState}
            onChange={setEditorState}
            customStyleMap={colorStyleMap}
          />
        </div>
      </div>
    </div>
  );
}

export default TextEditor;
