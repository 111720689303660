import React, { useCallback } from 'react'
import style from './Top.module.css';
import { useNavigate } from "react-router-dom"

function Top() {
  const navigate = useNavigate()
  const handleStart = useCallback(
    () => {
      navigate("/edit")
    },
    [],
  )

  return (
    <div className={style.global_wrapper}>
      <div className={style.hero_wrapper}>
        <h1>WEB 原稿用紙エディタ</h1>
        <div className={style.hero_header_grid}>
          <div className={style.header_read}>
            <p className={style.text1}>ブラウザ上で原稿用紙に縦書きで入力できる無料のエディタ！</p>
            <p className={style.text2}>下のボタンから直ぐに書き始められ、エディタに入力した内容を即座に原稿用紙のプレビューに反映して確認。さらにプレビューした原稿用紙の内容を印刷もできます。</p>
            <div>
              <a className={`${style.btn} ${style.btn_orange}`} 
                onClick={handleStart}
                href="javascript:void(0)">書き始める</a>
            </div>
          </div>
          <div className={style.header_paper_img}>
            <img src={`${process.env.PUBLIC_URL}/535768.png`} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Top